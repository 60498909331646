export const BASE_ROUTES = {
  microsite: "/",
  signup: "/signup",
  auth: "/auth",
  admin: "/admin",
  student: "/student",
  tutor: "/tutor",
};

// export const BASE_ROUTES_IGNORES: Partial<Record<keyof typeof BASE_ROUTES, string[]>> = {
//   microsite: Object.values(BASE_ROUTES).map,
// };
